<template>
    <form @submit.prevent="handleFormSubmit" method="POST" id="form" class="guardian-loging-form-container mx-auto">
        <h4 class="mb-3 h4 text-dark text-center">Barriers to learning</h4>

        <p class="body-txt-small"><span class="fw-bold">Concessions or access arrangement during test and exams</span> - Support put in place for a learner during assessments. Such as extra time, typing, a reader and a scribe. For more information, refer to the <a href="https://www.uctonlinehighschool.com/policies" target="_blank" class="text-primary-blue">Accessibility & Accommodations Policy</a>.</p>
        <div class="mb-3 form-floating mt-5 mt-md-0">
            <label for="BarriersToLearningSupportingDocumentation" class="long-label-2">Please upload any supporting documentation you have from a doctor, occupational therapist, physiotherapist, speech therapist, psychologist, counsellor and/or audiologist, or other health professional.</label>

            <vi-file-selector
                upload-field-name="BarriersToLearningSupportingDocumentation"
                :handle-upload="(formData) => handleUpload(formData, 'BarriersToLearningSupportingDocumentation', uploadUrlSupportingDocumentation, 'hasUploadedSupportingDocumentation')"
                :on-reset="() => onReset('hasUploadedSupportingDocumentation')"
                :max-file-size="8000000"
                accepted-file-types="image/*, .pdf"
            ></vi-file-selector>

            <p class="invalid-feedback d-block m-0" v-if="errors && errors['supporting_documentation']" v-html="errors['supporting_documentation'][0]"></p>
        </div>
        <div class="mb-1">
            <div class="form-check d-flex justify-content-center">
                <input :disabled="hasUploadedSupportingDocumentation" name="no_supporting_documentation" id="no_supporting_documentation" type="checkbox" class="form-check-input" v-model.trim="form.no_supporting_documentation.value">

                <label :disabled="hasUploadedSupportingDocumentation" for="no_supporting_documentation" class="form-check-label checkbox-text-sm ms-2">I don't currently have supporting information available to upload</label>
            </div>
        </div>
        <div class="mb-3 mt-3">
            <label for="concessions_or_access_arrangements">Has your child previously had any concessions/access arrangements in place during tests/exams?</label>

            <select class="form-select new-input mb-3" id="concessions_or_access_arrangements" name="concessions_or_access_arrangements" v-model.trim="form.concessions_or_access_arrangements.value" placeholder="Select">
                <option value="null" selected disabled>Please select</option>
                <option v-for="(item, key) in barrierModel.yesNo" :value="key" :key="key">{{ item }}</option>
            </select>

            <p class="invalid-feedback d-block m-0" v-if="errors && errors['concessions_or_access_arrangements']" v-html="errors['concessions_or_access_arrangements'][0]"></p>

            <p class="font-weight-bold">Disclaimer: Please note that concession applications have yearly deadline dates. For further clarity on these dates, please reach out to Student Records.</p>
        </div>
        <div v-if="isConcessionsOrAccessArrangements" class="mb-3 form-floating">
            <label for="ConcessionsOrAccessArrangements" class="long-label-2">Please upload any supporting documentation for you child having previous concessions approved e.g. a concession approval letter.</label>

            <vi-file-selector
                upload-field-name="ConcessionsOrAccessArrangements"
                :handle-upload="(formData) => handleUpload(formData, 'ConcessionsOrAccessArrangements', uploadUrlConcessionsOrAccessArrangements, 'hasUploadedConcessionsOrAccessArrangements')"
                :max-file-size="8000000"
                :on-reset="() => onReset('hasUploadedConcessionsOrAccessArrangements')"
                accepted-file-types="image/*, .pdf"
            ></vi-file-selector>

            <p class="invalid-feedback d-block m-0" v-if="errors && errors['supporting_documentation_for_concessions_or_access_arrangements']" v-html="errors['supporting_documentation_for_concessions_or_access_arrangements'][0]"></p>
        </div>
        <div v-if="isConcessionsOrAccessArrangements" class="mb-1">
            <div class="form-check d-flex justify-content-center">
                <input :disabled="hasUploadedConcessionsOrAccessArrangements" name="no_supporting_documentation_for_concessions_or_access_arrangements" id="no_supporting_documentation_for_concessions_or_access_arrangements" type="checkbox"  class="form-check-input" v-model.trim="form.no_supporting_documentation_for_concessions_or_access_arrangements.value">

                <label :disabled="hasUploadedConcessionsOrAccessArrangements" for="no_supporting_documentation_for_concessions_or_access_arrangements" class="form-check-label checkbox-text-sm ms-2">I don't currently have supporting information available to upload</label>
            </div>
        </div>
        <div v-if="isConcessionsOrAccessArrangements" class="mb-3 form-floating">
            <label for="support_in_place" class="long-label-2">What support has been put in place previously for your child (classroom facilitator, remedial therapy, tutoring, therapy services, additional teacher support, assistive technology etc.)? Please outline below.</label>

            <textarea name="support_in_place" id="support_in_place" class="form-control-textarea" v-model.trim="form.support_in_place.value"></textarea>

            <p class="invalid-feedback d-block m-0" v-if="errors && errors['support_in_place']" v-html="errors['support_in_place'][0]"></p>
        </div>
        <div class="mb-3 form-floating">
            <p class="body-txt-small">If you have any queries or concerns, please contact the Student Records Team at <a href="mailto:sro@uctohs.valentureonline.com">sro@uctohs.valentureonline.com</a></p>
        </div>
        <vi-error-flash
            v-if="unexpectedError"
            errorMessage="We are unable to save your application right now. Please try again later."
        ></vi-error-flash>
        <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
            <a :href="backUrl"  class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>

            <vi-button-spinner
                buttonId="appStepTwoBarriersToLearningButton"
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'new-btn-sm': true,
                    'mt-2': true,
                }"
                :buttonText="confirmButtonText"
                :loading="loading"
            ></vi-button-spinner>
        </div>
    </form>
</template>

<script>
import helperMixin from "../mixins/helper_mixin";
import Multiselect from 'vue-multiselect';

export default {
    name: "GuardianLearnerAppStepTwoBarriersToLearningForm",
    data: () => ({
        form: {
            supporting_documentation: {
                value: null,
            },
            no_supporting_documentation: {
                value: null,
            },
            concessions_or_access_arrangements: {
                value: null,
            },
            supporting_documentation_for_concessions_or_access_arrangements: {
                value: null,
            },
            no_supporting_documentation_for_concessions_or_access_arrangements: {
                value: null,
            },
            support_in_place: {
                value: null,
            },
        },
        errors: [],
        loading: false,
        unexpectedError: false,
        isShowBarriersOther: false,
        isConcessionsOrAccessArrangements: false,
        hasUploadedSupportingDocumentation: false,
        hasUploadedConcessionsOrAccessArrangements: false,
    }),
    mixins: [helperMixin],
    components: {
        'multiselect' : Multiselect
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
        applicationId: {
            type: String,
            required: true,
        },
        barrierModel: {
            type: Object,
            required: true,
        },
        submitRoute: {
            type: String,
            required: true,
        },
        confirmButtonText: {
            type: String,
            required: true,
        },
        backUrl: {
            type: String,
            required: true,
        },
        uploadUrlSupportingDocumentation: {
            type: String,
            required: true,
        },
        uploadUrlConcessionsOrAccessArrangements: {
            type: String,
            required: true,
        },
        getApplicationRoute: {
            type: String,
            required: true,
        },
    },
    methods: {
        async handleFormSubmit() {
            if (!this.checkForm()) {
                return;
            }

            let submitResponse = await this.submitForm();
        },
        async submitForm() {
            let data = {
                'supporting_documentation': this.form.supporting_documentation.value,
                'no_supporting_documentation': this.form.no_supporting_documentation.value,
                'concessions_or_access_arrangements': this.form.concessions_or_access_arrangements.value,
                'supporting_documentation_for_concessions_or_access_arrangements': this.form.supporting_documentation_for_concessions_or_access_arrangements.value,
                'no_supporting_documentation_for_concessions_or_access_arrangements': this.form.no_supporting_documentation_for_concessions_or_access_arrangements.value,
                'support_in_place': this.form.support_in_place.value,
                'has_uploaded_concessions_or_access_arrangements': this.hasUploadedConcessionsOrAccessArrangements,
                'has_uploaded_supporting_documentation': this.hasUploadedSupportingDocumentation,
            };

            data.user_id = this.userId;

            try {
                let response = await axios.post(this.submitRoute, data);

                if (response.status === 200) {
                    location.href = response.data.redirect;
                }
            } catch (error) {
                const data = error.response.data;

                if (error.response.status === 422) {
                    this.errors = data.errors;
                } else {
                    this.unexpectedError = true;
                }

                this.loading = false;
            }
        },
        checkForm: function () {
            this.loading = true;

            this.errors = [];

            if (!this.hasUploadedSupportingDocumentation && !this.form.no_supporting_documentation.value) {
                this.errors['supporting_documentation'] = [ 'Please upload supporting documentation' ];
            }

            let concessionsOrAccessArrangements = this.form.concessions_or_access_arrangements.value;

            if (!concessionsOrAccessArrangements) {
                this.errors['concessions_or_access_arrangements'] = [ 'Please specify concessions or access arrangements' ];
            }

            if (concessionsOrAccessArrangements == 1) {
                if (!this.hasUploadedConcessionsOrAccessArrangements) {
                    if (!this.form.no_supporting_documentation_for_concessions_or_access_arrangements.value) {
                        this.errors['supporting_documentation_for_concessions_or_access_arrangements'] = [ 'Please upload supporting documentation for concessions or access arrangements' ];
                    }
                }
                if (!this.form.support_in_place.value) {
                    this.errors['support_in_place'] = [ 'Please specify support in place' ];
                }
            }


            let errorCount = 0;
            for (let key in this.errors) {
                if (this.errors.hasOwnProperty(key)) {
                    errorCount++;
                }
            }

            if (errorCount == 0) {
                return true;
            }

            this.loading = false;
        },
        handleUpload(formData, uploadField, url, uploadValidationField) {
            formData.append('applicationId', this.applicationId);
            formData.append('uploadFileField', uploadField);

            const headers = {
                'Content-Type': 'multipart/form-data',
            };

            return axios.post(url, formData, headers)
                .then(msg => {
                    this[uploadValidationField] = true;

                    return 'Document successfully uploaded.';
                })
                .catch(error => {
                    console.error(error);

                    return 'An error has occurred, please retry.';
                });
        },
        onReset(uploadValidationField) {
            this[uploadValidationField] = false;
        }
    },
    created () {
        this.getApplication(this.getApplicationRoute).then(response => {
            this.application = response.data.application;

            this.loadForm(this.application);
        })
        .catch(error => {
            if (error.response.status === 500) {
                this.unexpectedError = true;
            };
        })
    },
    watch: {
        'form.concessions_or_access_arrangements.value': {
            handler: function (val) {
                this.isConcessionsOrAccessArrangements = val == 1 ? true : false;
            },
            deep: true
        },
    }
};
</script>

<style scoped lang="scss">
.checkbox-text-sm {
    font-size: 0.875rem; // 14px
}
</style>