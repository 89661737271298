<template>
    <form @submit.prevent="onSubmitHandler" class="pb-3">
        <h4 class="fw-bold billing-heading">Card details:</h4>

        <div class="row align-items-end">
            <div v-if="error" class="col-12 pb-0">
                <div class="alert alert-danger mb-0" role="alert" v-html="error"></div>
            </div>

            <div class="col-12 col-md">
                <vi-account-input
                    label="Current card in use:"
                    name="currentCard"
                    :required="true"
                    :disabled="disabled"
                    v-model="currentPaymentSource.card.masked_number"
                    class="rounded-white-input card-input-component"
                ></vi-account-input>
            </div>

            <div class="col-12 col-md">
                <vi-account-input
                    label="Expiry date"
                    name="expiryDate"
                    :required="true"
                    :disabled="disabled"
                    v-model="cardExpiry"
                    class="rounded-white-input card-input-component"
                ></vi-account-input>
            </div>

            <div class="col-12 col-md">
                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'rounded-pill': true,
                        'border': true,
                        'border-color-white': true,
                        'py-2': true,
                        'px-4': true,
                    }"
                    buttonText="Edit"
                    :loading="loading"
                ></vi-button-spinner>
            </div>

            <p class="col-12 disclaimer-text">
                R1.00 will be temporarily reserved in order to verify the card via your bank (3D Secure), and you will see the "pending verification" status, until the charge is voided. If you have any issues, please check again in 24 hours or contact our support team at <a class="text-white" href="mailto:finance@valentureinstitute.com">finance@valentureinstitute.com</a>.
            </p>
        </div>
    </form>
</template>

<script>
    import ViAccountInput from '../account/ViAccountInput.vue';
    import * as Sentry from "@sentry/vue";

    export default {
        name: 'CardDetailsSection',
        components: {
            ViAccountInput
        },
        data() {
            return {
                disabled: true,
                loading: false,
                error: null,
                chargebee: null,
                currentPaymentSource: this.paymentSource,
            };
        },
        props: {
            paymentSource: {
                type: Object,
                default: null,
            },
            chargebeeKey: {
                type: String,
            },
            chargebeeSite: {
                type: String,
            },
            cardDetailsUpdateUrl: {
                type: String,
            },
        },
        methods: {
            onSubmitHandler() {
                this.loading = true;
                this.error = null;

                axios.get(this.cardDetailsUpdateUrl)
                    .then(success => {
                        this.chargeBee.openCheckout({
                            hostedPage: async () => {
                                return success.data.hostedPage;
                            },
                            close: () => {
                                // User has closed the popup. Refresh the payment source.
                                this.loading = true;
                                this.$emit('hide-add-payment-source');

                                axios.get(success.data.refreshUrl)
                                    .then(response => {
                                        if (response.data.paymentSource) {
                                            this.currentPaymentSource = response.data.paymentSource;
                                        }
                                    })
                                    .catch(error => {
                                        this.error = error.response.data.error;
                                        this.clearErrorAfter(6000);
                                    })
                                    .finally(() => {
                                        this.loading = false;
                                    });
                            },
                        });
                    })
                    .catch(error => {
                        if (!error.hasOwnProperty('response')) {
                            Sentry.captureException(error);
                        }

                        this.error = error.response.data.error;
                        this.clearErrorAfter(6000);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            clearErrorAfter(ms) {
                setTimeout(() => {
                    this.error = null;
                }, ms);
            }
        },
        computed: {
            cardExpiry() {
                if (!this.currentPaymentSource.card.expiry_month) {
                    return '';
                }

                return `${this.currentPaymentSource.card.expiry_year} / ${this.currentPaymentSource.card.expiry_month}`;
            }
        },
        mounted() {
            this.chargeBee = Chargebee.init({
                site: this.chargebeeSite,
                publishableKey: this.chargebeeKey,
                iframeOnly: true,
            });
        },
    };
</script>

<style scoped lang="scss">
.disclaimer-text {
    font-size: 0.875rem; // 14px
}

</style>
