<template>
    <div class="guardian-loging-form-container mx-auto w-100">
        <h4 class="mb-4 text-pastel-blue">
            {{ headerText }}
        </h4>

        <p class="mb-4">Please select an option below or fill in your details, to create an account.</p>

        <div class="external-login-buttons my-4">
            <a :href="googleRedirectLink" class="new-btn new-btn-dark new-btn-ghost">
                <img :src="'/portal-data/uct/icons/google-icon-64x64.png'" alt="Google's G logo" class="btn-icon">
                Log in/Sign up with Google
            </a>

            <a :href="appleRedirectLink" class="new-btn new-btn-dark new-btn-ghost">
                <i class="bi bi-apple btn-icon"></i>
                Log in/Sign up with Apple
            </a>
        </div>

        <form @submit.prevent="submit" class="text-start" id="form" autocomplete="off">
            <input type="hidden" name="offerforgeId" v-model="form.offerforge_id">

            <div class="form-floating mb-3">
                <input type="text" class="form-control new-input" id="firstNameInput" placeholder="First Name"
                    v-model.trim="form.first_name" />
                <label for="firstNameInput">First Name</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['first_name']"
                    v-html="errors['first_name'][0]"></p>
            </div>
            <div class="form-floating mb-3">
                <input type="text" class="form-control new-input" id="lastNameInput" placeholder="Last Name"
                    v-model.trim="form.last_name" />
                <label for="lastNameInput">Last Name</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['last_name']"
                    v-html="errors['last_name'][0]"></p>
            </div>
            <div class="mb-3">
                <label for="date_of_birth" class="form-label">Date of Birth</label>
                <div class="form-row d-flex">
                    <div class="form-floating me-2">
                        <select class="form-select" :class="!birthYear ? 'text-muted' : ''" name="year" id="year"
                            v-model.trim="birthYear" required>
                            <option :value="null" selected disabled>YYYY</option>
                            <option v-for="year in getYears" :value="year" :key="`year-${year}`">{{ year }}</option>
                        </select>
                        <label for="year">Year</label>
                    </div>
                    <div class="form-floating me-2">
                        <select class="form-select" :class="!birthMonth ? 'text-muted' : ''" name="month" id="month"
                            v-model.trim="birthMonth" required>
                            <option :value="null" selected disabled>MM</option>
                            <option v-for="(month, key) in yearMonths" :value="key + 1" :key="`month-${month}`">{{ month
                            }}</option>
                        </select>
                        <label for="month">Month</label>
                    </div>
                    <div class="form-floating">
                        <select class="form-select" :disabled="!birthMonth || !birthYear"
                            :class="!birthDay ? 'text-muted' : ''" name="day" id="day" v-model.trim="birthDay"
                            required>
                            <option :value="null" selected disabled>DD</option>
                            <option v-for="(day, key) in monthDays" :value="day" :disabled="key + 1 > daysInMonth"
                                :key="`day-${day}`">{{ day }}</option>
                        </select>
                        <label for="day">Day</label>
                    </div>
                </div>
                <p class="invalid-feedback d-block my-1" v-if="birthDay > daysInMonth">Please select a valid day for the
                    month selected</p>
                <p class="invalid-feedback d-block my-1" v-if="birthYear > (new Date()).getFullYear() - 17">This account
                    must be created by an adult i.e. the guardian of a minor learner; or an adult wishing to participate
                    in Adult Matric.</p>
                <p class="invalid-feedback d-block my-1" v-if="errors && errors['date_of_birth']"
                    v-html="errors['date_of_birth'][0]"></p>
            </div>
            <div class="form-floating mb-3">
                <input type="email" class="form-control new-input" id="emailInput" placeholder="Email Address"
                    v-model.trim="form.email" :disabled="isGuardianTransfer"/>
                <label for="emailInput">Email Address</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['email']" v-html="errors['email'][0]">
                </p>
            </div>
            <div class="row mb-4 mb-md-3">
                <div class="col-12 col-md-4">
                    <div class="form-floating">
                        <select class="form-select new-input" id="dialCodeSelect"
                            aria-label="Floating label select example" v-model.trim="form.mobile_number_country">
                            <option :value="null" selected disabled>Please select</option>
                            <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
                        </select>
                        <label for="dialCodeSelect">Dialing Code</label>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="form-floating">
                        <input type="number" class="form-control new-input" id="mobileNumberInput"
                            placeholder="Mobile Number" v-model.trim="form.mobile_number" />
                        <label for="mobileNumberInput">Mobile Number</label>
                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['mobile_number']"
                            v-html="errors['mobile_number'][0]"></p>
                    </div>
                </div>
            </div>
            <div class="form-floating mb-3 mt-2 mt-md-0">
                <input type="text" class="form-control new-input" id="howDidYouHearAboutUs" placeholder="How did you hear about UCT Online High School?" v-model.trim="form.how_did_you_hear"/>
                <label for="howDidYouHearAboutUs" class="long-label-select">How did you hear about UCT Online High School?</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['how_did_you_hear']" v-html="errors['how_did_you_hear'][0]"></p>
            </div>

            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="We are unable to sign you up right now. Please try again later."
            ></vi-error-flash>

            <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center w-100 my-4">
                <vi-button-spinner
                    v-if="isGuardianTransfer"
                    buttonId="appStepOneButton"
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-lg': true,
                    }"
                    buttonText="Start Transfer Process"
                    :loading="loading"
                    @button-click="startApplication()"
                ></vi-button-spinner>

                <vi-button-spinner
                    v-if="!isGuardianTransfer"
                    buttonId="appStepOneButton"
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-lg': true,
                    }"
                    buttonText="Start Application"
                    :loading="loading"
                    @button-click="startApplication()"
                ></vi-button-spinner>

                <vi-button-spinner
                    v-if="llsApplicationsActive && (!this.preferredCurriculum || isCapsPreferred) && !isGuardianTransfer"
                    buttonId="appStepOneButtonScholarship"
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-lg': true,
                        'ms-0 ms-md-2 mt-2 mt-md-0': true,
                    }"
                    buttonText="Start Bursary Application (Grade 8-11)"
                    :loading="loading"
                    @button-click="startScholarshipApplication()"
                ></vi-button-spinner>
            </div>

            <div class="login-help-links">
                <a :href="loginRoute" class="mt-1 d-block text-decoration-none">Already have an account? Click here to
                    login.</a>
                <a href="mailto:techsupport@uctohs.valentureonline.com" target="_blank"
                    class="mt-1 d-block text-decoration-none">Contact Support</a>
            </div>
        </form>
    </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

export default {
    name: "GuardianSignUpForm",
    data: function () {
        return {
            form: {
                title: null,
                first_name: null,
                last_name: null,
                email: null,
                mobile_number: null,
                mobile_number_country: "ZA",
                how_did_you_hear: null,
                preferred_curriculum: null,
                date_of_birth: null,
                password: null,
                offerforge_id: this.offerforgeId,
            },
            birthYear: null,
            birthMonth: null,
            birthDay: null,
            yearMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            monthDays: [],
            loading: false,
            unexpectedError: false,
            errors: null,
            studentEmailDomains: ['valentureonline'],
            buttonType: null,
            emailFromUrl: "",
            isGuardianTransfer: false,
        };
    },
    props: [
        'countries',
        'dialCodes',
        'title',
        'submitRoute',
        'loginRoute',
        'preferredCurriculum',
        'googleRedirectLink',
        'llsApplicationsActive',
        'offerforgeId',
        'appleRedirectLink',
    ],
    computed: {
        daysInMonth() {
            if (this.birthMonth && this.birthYear) {
                return new Date(this.birthYear, this.birthMonth, 0).getDate();
            }

            return 31;
        },
        getYears() {
            let twentyYearsAgo = (new Date()).getFullYear() - 10;
            let years = Array.from({ length: 100 }, (v, k) => twentyYearsAgo - k);

            return years;
        },
        isEmailBlocked() {
            if (this.form.email) {
                return this.studentEmailDomains.some(domain => this.form.email.includes(domain));
            }

            return false;
        },
        isAdultMatricPreferred() {
            return this.preferredCurriculum === 'adult-matric';
        },
        isCapsPreferred() {
            return this.preferredCurriculum === 'caps';
        },
        headerText() {
            if (this.isAdultMatricPreferred) {
                return 'Adult Information';
            }

            if (this.isCapsPreferred) {
                return 'Guardian Information';
            }

            return 'Guardian or Adult Information';
        }
    },
    mounted() {
        this.form.preferred_curriculum = this.preferredCurriculum;
        this.monthDays = Array.from({ length: 31 }, (v, k) => k + 1);
    },
    methods: {
        validateDob() {
            if (this.birthYear && this.birthMonth && this.birthDay) {
                return true;
            }

            return false;
        },
        async submit() {
            if (this.isEmailBlocked) {
                this.errors = {
                    email: [
                        'You are trying to sign up to the guardian portal using a student email address, please sign up using a personal email address or visit the student portal.'
                    ]
                };

                return;
            }

            if (!this.validateDob()) {
                this.errors = {
                    date_of_birth: [
                        'Please select a valid date of birth.'
                    ]
                };

                return;
            }

            this.loading = true;
            this.unexpectedError = false;

            try {
                let dob = new Date(this.birthYear, this.birthMonth - 1, this.birthDay);
                this.form.date_of_birth = dob.toISODate();

                let submitUrl = this.buildSubmitUrlWithUtmParams(this.submitRoute);

                let response = await axios.post(submitUrl, this.form);

                if (response.status === 200) {
                    this.triggerGaEvent(this.form.how_did_you_hear, response.userId);

                    location.href = response.data.redirect;
                }

                this.loading = false
            } catch (error) {
                Sentry.captureException(error);

                if (!error.hasOwnProperty('response') || !error.response.hasOwnProperty('data')) {
                    Sentry.withScope((scope) => {
                        scope.setContext('Form', this.form);

                        Sentry.captureException(error);
                    });

                    this.unexpectedError = true;
                    this.loading = false

                    return;
                }

                const data = error.response.data;

                if (error.response.status === 422) {
                    this.errors = data.errors
                } else if (error.response.status === 501) {
                    this.unexpectedError = true;
                } else {
                    this.unexpectedError = true;
                }
                this.loading = false
            }

            if (!this.birthYear) {
                this.errors.push('Name required.');
            }
        },
        /**
         * Build new Submit URL preserving only the UTM params on the current location.
         *
         * @param {string} url Submit URL
         */
        buildSubmitUrlWithUtmParams(url) {
            let submitUrl = new URL(url);
            let urlParams = new URLSearchParams(window.location.search);
            let submitUrlParams = new URLSearchParams();

            let utmParams = [
                'utm_source',
                'utm_medium',
                'utm_campaign',
                'utm_content',
                'utm_term',
            ];

            for (const utmParam of utmParams) {
                if (urlParams.has(utmParam)) {
                    submitUrlParams.set(utmParam, urlParams.get(utmParam));
                }
            }

            submitUrl.search = submitUrlParams.toString();

            return submitUrl.toString();
        },
        startApplication() {
            this.form.scholarship_interest = false;
            this.submit();
        },
        startScholarshipApplication() {
            this.form.scholarship_interest = true;
            this.submit();
        },
        triggerGaEvent(userId) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'createAccount',
                'userid': userId,
            });
        }
    },
    created() {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const emailFromUrl = url.searchParams.get('email');
        const guardianTransferParam = url.searchParams.get('guardian_transfer');

        if (emailFromUrl) {
            this.form.email = decodeURIComponent(emailFromUrl);
        }

        if (guardianTransferParam) {
            this.isGuardianTransfer = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.google-signup-btn {
    max-width: none;
}
</style>
