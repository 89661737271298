<template>
    <div class="guardian-loging-form-container mx-auto w-100">
        <div v-if="loginError" class="alert alert-danger" v-html="loginError"></div>

        <form @submit.prevent="submit" class="text-start py-4 py-md-0 px-3 px-md-0" id="form">

            <vi-alert :type="`vi-error`" v-if="sessionExpired">
                Your session has expired. Please log in again.
            </vi-alert>
            <!-- enrolled leanrers login section -->
            <div class="mb-5 pb-5 mt-3" v-if="!isGuardianTransfer">
                <h4 class="text-pastel-blue mb-4">Enrolled Learners</h4>
                <a href="/saml2/google/login" class="new-btn new-btn-dark new-btn-sm me-0 me-md-2 mb-2 mb-md-0 w-100 d-block">Login</a>
            </div>

            <div v-else class="mb-4 pb-4">
                <h1 class="h3 mb-4">Parent/Guardian Change Request</h1>

                <a :href="transferDecline" class="new-btn new-btn-dark new-btn-sm me-0 me-md-2 mb-2 mb-md-0 px-3">No, I do not want to continue</a>
            </div>



            <!-- applicants and guardians login section section -->
            <h4 class="text-pastel-blue mb-4">Applicants and Guardians</h4>
            <p v-if="((this.emailExists && !isGuardianTransfer) || noPassword ) && !['google', 'apple'].includes(authProvider)" class="text-danger pb-2">
                You already have an account with us! Please sign in using your existing email address and password. If you've forgotten your password, reset it using the link below:
            </p>

            <p v-else-if="authProvider" class="text-danger pb-2">
                You already have an account with us! Please use {{ authProvider[0].toUpperCase() + authProvider.slice(1) }} to sign in.
            </p>

            <div v-if="!isUnverified">
                <div class="form-floating mb-3">
                    <input type="email" class="form-control new-input" name="floatingInput" id="floatingInput" placeholder="Email Address" v-model.lazy.trim="form.email" @input="checkEmail()" :disabled="isGuardianTransfer"/>

                    <label for="floatingInput">Email Address</label>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['email']" v-html="errors['email'][0]"></p>
                </div>
            </div>
            <p v-if="(isStudentEmail && form.email && !emailNotFound) || showStudentEmailAlert" class="text-danger pb-2">Please use the login button under 'Enrolled Learners' to sign in via your google account.</p>
            <div  class="form-floating mb-3">
                <vi-password-input
                    fieldId="passwordFloatingInput"
                    label="Password"
                    v-model="form.password"
                    :errorMessage="passErrors('password')"
                ></vi-password-input>
            </div>
            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="We are unable to log you in right now. Please try again later."
            ></vi-error-flash>
            <p v-if="emailNotFound" class="text-danger pb-2">
                Email not found. If you would like to apply to the school, please sign up below.
            </p>
            <p v-if="isUnverified" class="text-pastel-blue">
                Your email address has not been verified. Please check your inbox for a verification email.
                <email-code-verification
                    :verify-route="verifyRoute"
                    :resend-route="resendRoute"
                    :email="form.email"
                    class="mt-3"
                ></email-code-verification>
            </p>
            <div v-if="showBottomButtons">
                <div class="d-flex login-buttons flex-column flex-md-row justify-content-center justify-content-md-start w-100 flex-wrap align-content-center my-4">
                    <vi-button-spinner
                        :buttonClass="{
                            'new-btn': true,
                            'new-btn-dark': true,
                            'new-btn-sm': true,
                            'me-0': true,
                            'me-md-2': true,
                            'mb-2': true,
                            'mb-md-0': true
                        }"
                        buttonText="Login"
                        :loading="loading"
                    ></vi-button-spinner>
                    <a :href="signUpRoute" class="new-btn new-btn-dark new-btn-sm me-0 me-md-2 mb-2 mb-md-0" @click="checkEmailSignUp">Sign Up</a>

                </div>

                <div class="external-login-buttons my-4">
                    <a :href="googleRedirectLink" class="new-btn new-btn-dark new-btn-ghost">
                        <img :src="'/portal-data/uct/icons/google-icon-64x64.png'" alt="Google's G logo" class="btn-icon">
                        Log in/Sign up with Google
                    </a>

                    <a :href="appleRedirectLink" class="new-btn new-btn-dark new-btn-ghost">
                        <i class="bi bi-apple btn-icon"></i>
                        Log in/Sign up with Apple
                    </a>
                </div>

                <div class="login-help-links mb-4">
                    <a :href="forgotRoute" class="mt-1 d-block text-decoration-none">Forgot Password?</a>
                    <a href="mailto:techsupport@uctohs.valentureonline.com" target="_blank" class="mt-1 d-block text-decoration-none">Contact Support</a>
                </div>
                <a href="https://onelink.to/d4zd8c" target="_blank" class="new-btn download-btn new-btn-xl px-4 py-2 rounded-pill text-uppercase text-decoration-none mt-4 d-block d-sm-none fw-bold d-flex justify-content-center align-items-center">
                    Download Our Guardian App <img :src="'/portal-data/uct/download-white.png'" alt="download-icon" width="13px" class="ms-1">
                </a>
            </div>
        </form>
    </div>
</template>

<script>
    import ViPasswordInput from './ViPasswordInput.vue';
    import * as Sentry from "@sentry/vue";

    export default {
        name: "GuardianLoginForm",
            data: () => ({
                form: {
                    password: null,
                    email: null,
                },
                loading: false,
                unexpectedError: false,
                errors: null,
                showBottomButtons: true,
                emailExists: false,
                authProvider: null,
                isUnverified: false,
                isGuardianEmail: false,
                isStudentEmail: false,
                busySubmitting: false,
                emailNotFound: false,
                noPassword: false,
                isGuardianTransfer: false,
            }),
            props: {
                signUpRoute: {
                    type: String,
                    required: true,
                },
                forgotRoute: {
                    type: String,
                    required: true,
                },
                submitRoute: {
                    type: String,
                    required: true,
                },
                checkEmailRoute: {
                    type: String,
                    required: true,
                },
                homeRoute: {
                    type: String,
                    required: true,
                },
                learnerRoute: {
                    type: String,
                    required: true,
                },
                verifyRoute: {
                    type: String,
                    required: true,
                },
                resendRoute: {
                    type: String,
                    required: true,
                },
                googleRedirectLink: {
                    type: String,
                    required: true,
                },
                appleRedirectLink: {
                    type: String,
                    required: true,
                },
                showStudentEmailAlert: {
                    type: Boolean,
                    default: false,
                },
                loginError: {
                    default: null,
                },
                sessionExpired: {
                    type: Boolean,
                    default: false,
                },
                transferDecline: {
                    type: String | null,
                    required: false,
                },
            },
            mounted () {
                this.busySubmitting = false;

                const params = new URLSearchParams(location.search);

                if (params.has('existingEmail')) {
                    this.emailExists = true;
                    this.form.email = params.get('existingEmail');
                }

                if (params.has('authSource')) {
                    this.authProvider = params.get('authSource');
                }

                if (params.has('guardian_transfer')) {
                    this.isGuardianTransfer = true;
                }
            },
            methods: {
                async submit() {
                    this.loading = true;
                    this.unexpectedError = false;

                    try {

                        if (!this.form.email) {
                            this.errors = {
                                email: ['Please provide your email address.']
                            };

                            this.loading = false;
                            return false;
                        } else if (!this.form.password) {
                            this.errors = {
                                password: ['Please enter your password.']
                            };

                            this.loading = false;
                            return false;
                        }

                        await this.validateGuardianEmail(this.form.email);
                        if (!this.isGuardianEmail) {
                            this.loading = false;
                            return false;
                        }

                        this.busySubmitting = true;
                        this.errors = {};

                        if (!this.form.password) {
                            this.errors.password = ['Please enter your password.'];
                            this.loading = false;
                            this.busySubmitting = false;
                            return false;
                        }

                        let response = await axios.post(this.submitRoute, this.form);

                        if (response.status === 200){
                            this.triggerGaEvent(response.userId);

                            location.href = this.homeRoute;
                        }
                    } catch (error) {
                        // error is an object we're not expecting
                        if (!error.hasOwnProperty('response')) {
                            Sentry.withScope((scope) => {
                                scope.setContext('Form', this.form);

                                Sentry.captureException(error);
                            });

                            this.unexpectedError = true;
                            this.busySubmitting = false;
                            this.loading = false;

                            return;
                        }

                        const data = error.response.data;

                        if (error.response.status === 422 || error.response.status === 429 ) {
                            this.errors = data.errors
                        } else{
                            this.unexpectedError = true;
                        }

                        this.busySubmitting = false;
                        this.loading = false;
                    }
                },
                passErrors(field) {
                    return this.errors && this.errors[field] && this.errors[field][0] ? this.errors[field][0] : '';
                },
                triggerGaEvent(userId) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'login',
                        'userid': userId,
                    });
                },
                checkEmail(value) {
                    if (!this.isGuardianEmail) {
                        let loginButton = document.getElementById('vi-submit');
                        loginButton.removeAttribute('disabled');
                    }
                },
                checkEmailSignUp(event) {
                    event.preventDefault();
                    let emailValue = document.getElementById('floatingInput').value;
                    let updatedSignUpRoute = `${this.signUpRoute}?email=${encodeURIComponent(emailValue)}`;
                    window.location.href = updatedSignUpRoute;
                },
                async validateGuardianEmail(value) {
                    this.errors = {};
                    this.busySubmitting = true;
                    this.emailNotFound = false;
                    this.isGuardianEmail = false;

                    if (!this.form.email) {
                        this.errors = {
                            email: ['Please provide your email address.']
                        };

                        this.busySubmitting = false;

                        return false;
                    }

                    const regex = /valentureonline.com$/;

                    if (regex.test(value)) {
                        this.isGuardianEmail = false;
                        this.isStudentEmail = true;
                    }

                    let response = await axios.post(this.checkEmailRoute, this.form);

                    if (response.data.studentEmail) {
                        this.isGuardianEmail = false;
                        this.isStudentEmail = true;
                        this.authProvider = null;
                    } else if (response.data.adultEmail) {
                        this.showBottomButtons = true;
                        this.isGuardianEmail = true;
                        this.isStudentEmail = false;
                        this.busySubmitting = false;
                        this.isUnverified = false;
                        this.authProvider = null;
                    } else if (response.data.emailNotFound) {
                        this.showBottomButtons = true;
                        this.isGuardianEmail = false;
                        this.isStudentEmail = false;
                        this.emailNotFound = true;
                        this.busySubmitting = false;
                        this.isUnverified = false;
                        this.authProvider = null;
                    } else if (response.data.unverifiedEmail) {
                        this.showBottomButtons = false;
                        this.isGuardianEmail = false;
                        this.isStudentEmail = false;
                        this.busySubmitting = false;
                        this.isUnverified = true;
                        this.authProvider = null;
                    } else if (response.data.noPassword) {
                        this.showBottomButtons = true;
                        this.isGuardianEmail = false;
                        this.isStudentEmail = false;
                        this.busySubmitting = false;
                        this.isUnverified = false;
                        this.noPassword = true;
                        this.authProvider = null;
                    } else if (response.data.authSource) {
                        this.showBottomButtons = true;
                        this.isGuardianEmail = false;
                        this.isStudentEmail = false;
                        this.busySubmitting = false;
                        this.isUnverified = false;
                        this.noPassword = false;
                        this.authProvider = response.data.authSource;
                    } else {
                        this.unexpectedError = true;
                    }
                }
            },
        };
</script>

<style lang="scss">
@import '../../sass/abstract/colours.scss';

.login-help-links {
    a {
        width: fit-content;
    }
}

.login-buttons {
    .new-btn {
        flex-basis: fit-content;
        min-width: 120px;
        max-width: none;
    }
}




</style>
